<template>
  <div
    class="pic-zoom-container oMidBox"
    ref="oMidBox"
    @mousemove="mousemoveHandler"
    @mouseleave="mouseleaveHandler"
    @mouseenter="mouseenterHandler"
  >
    <div class="physics-size" v-if="isShowPhysicsSize">
      <div class="label">物理尺寸：</div>
      <div class="name">{{ physicsSize.width }} * {{ physicsSize.height }}</div>
    </div>
    <img class="image oMidBoxImg" :src="url" alt="" ref="oMidBoxImg" @load="loadedHandler" />
    <!-- 阴影盒子 -->
    <div class="shade" ref="shadowBox" v-show="show"></div>
    <div class="largeBox" ref="largeBox" v-show="show">
      <img :src="url" ref="oLargeBoxImg" class="largeBoxImg" />
    </div>
  </div>
</template>

<script>
import { resizeMixin } from '@/mixins'
export default {
  mixins: [resizeMixin],
  props: {
    url: {
      type: String
    },
    shadowBoxWidth: {
      type: Number
    },
    largeBoxWidth: {
      type: Number,
      default: 400
    },
    dpi: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      imgDom: null,
      show: false,
      physicsSize: {}
    }
  },
  computed: {
    //是否展示物理像素
    isShowPhysicsSize({ physicsSize }) {
      return Object.keys(physicsSize).length > 0 && this.dpi > 0
    }
  },

  watch: {
    dpi(newVal) {
      const isRender = !!Object.keys(this.isShowPhysicsSize || {}).length
      if (!isRender && this.imgDom) {
        const { width, height } = this.imgDom
        const dpiCmFromIn = newVal / 2.54
        this.physicsSize = {
          width: (width / dpiCmFromIn).toFixed(2) + 'cm',
          height: (height / dpiCmFromIn).toFixed(2) + 'cm'
        }
      }
    }
  },

  methods: {
    //图片加载成功的回调
    loadedHandler() {
      const img = new Image()
      img.onload = () => {
        this.$nextTick(() => {
          // 防止放大镜空白
          this.onresize()
          const dpiCmFromIn = this.dpi / 2.54
          const { width, height } = img

          if (typeof width === 'number' && typeof height === 'number') {
            if (!this.dpi) {
              return (this.imgDom = img)
            }
            this.physicsSize = {
              width: (width / dpiCmFromIn).toFixed(2) + 'cm',
              height: (height / dpiCmFromIn).toFixed(2) + 'cm'
            }
          }
        })
      }
      img.src = this.url
    },

    init() {
      this.onresize()
    },
    onresize() {
      // console.log('resize')
      this.show = true
      this.$nextTick(() => {
        let oLargeBoxImg = this.$refs.oLargeBoxImg
        let oMidBox = this.$refs.oMidBox
        let largeBox = this.$refs.largeBox
        let shadowBox = this.$refs.shadowBox

        if (!oLargeBoxImg || !oMidBox || !largeBox || !shadowBox) return

        largeBox.style.width = this.largeBoxWidth
        largeBox.style.height = this.largeBoxWidth
        if (this.shadowBoxWidth) {
          shadowBox.style.width = this.shadowBoxWidth + 'px'
          shadowBox.style.height = this.shadowBoxWidth + 'px'
          oLargeBoxImg.style.width = oMidBox.offsetWidth * (this.largeBoxWidth / this.shadowBoxWidth) + 'px'
        } else {
          const { offsetWidth: midOffsetWidth, offsetHeight: midOffsetHeight } = this.$refs.oMidBoxImg
          const { offsetWidth } = oLargeBoxImg
          const radio = offsetWidth / this.largeBoxWidth
          shadowBox.style.width = midOffsetWidth / radio + 'px'
          shadowBox.style.height = midOffsetWidth / radio + 'px'
        }

        this.show = false
      })
    },
    mouseenterHandler() {
      this.show = true
    },
    mouseleaveHandler() {
      this.show = false
    },
    mousemoveHandler(e) {
      this.show = true
      this.$nextTick(() => {
        let shadowBox = this.$refs.shadowBox
        let oMidBox = this.$refs.oMidBox
        let oMidBoxImg = this.$refs.oMidBoxImg
        let largeBox = this.$refs.largeBox
        let oLargeBoxImg = this.$refs.oLargeBoxImg

        if (!shadowBox || !oMidBox || !oMidBoxImg || !oLargeBoxImg) return
        let widnowDirTop = oMidBox && oMidBox.getBoundingClientRect().top
        let widnowDirLeft = oMidBox && oMidBox.getBoundingClientRect().left
        let oMaxMidLeft = oMidBox.offsetWidth - shadowBox.offsetWidth
        let oMidLeft = e.pageX - shadowBox.offsetWidth / 2
        let oMidTop = e.pageY - shadowBox.offsetHeight / 2
        shadowBox.style.left = oMidLeft - widnowDirLeft + 'px'
        shadowBox.style.top = oMidTop - widnowDirTop + 'px'
        //  大图容器跟随鼠标
        largeBox.style.left = e.pageX + shadowBox.offsetWidth / 2 - widnowDirLeft + 'px'
        largeBox.style.top = e.pageY + shadowBox.offsetHeight / 2 - widnowDirTop + 'px'

        // 边界处理
        if (shadowBox.style.left < '0px') {
          //  阴影盒子
          shadowBox.style.left = '0px'
          //  大图盒子
          largeBox.style.left = shadowBox.offsetWidth + 'px'
        }
        if (parseInt(shadowBox.style.left) > oMaxMidLeft) {
          //   阴影盒子
          shadowBox.style.left = oMaxMidLeft + 'px'
        }
        if (shadowBox.style.top < '0px') {
          //  阴影盒子
          shadowBox.style.top = '0px'
          //   大图盒子
          largeBox.style.top = shadowBox.offsetHeight + 'px'
        }
        if (parseInt(shadowBox.style.top) > oMidBoxImg.offsetHeight - shadowBox.offsetHeight) {
          // 阴影盒子
          shadowBox.style.top = oMidBoxImg.offsetHeight - shadowBox.offsetHeight + 'px'
          //   大图盒子
          largeBox.style.top = oMidBoxImg.offsetHeight + 'px'
        }
        if (parseInt(largeBox.style.left) > oMidBox.offsetWidth - largeBox.offsetWidth) {
          // 大图盒子
          largeBox.style.left = e.pageX - shadowBox.offsetWidth / 2 - widnowDirLeft - largeBox.offsetWidth + 'px'
          if (parseInt(shadowBox.style.left) === oMaxMidLeft) {
            //   大图盒子
            largeBox.style.left = oMidBox.offsetWidth - largeBox.offsetWidth - shadowBox.offsetWidth + 'px'
          }
        }
        if (parseInt(largeBox.style.left) < 0) {
          largeBox.style.left = '0px'
        }

        // 大图显示部分
        oLargeBoxImg.style.left =
          -oLargeBoxImg.offsetWidth * (parseInt(shadowBox.style.left) / oMidBox.offsetWidth) + 'px'
        oLargeBoxImg.style.top =
          -oLargeBoxImg.offsetHeight * (parseInt(shadowBox.style.top) / oMidBox.offsetHeight) + 'px'

        this.$emit('picMouseMove', {
          left: parseInt(shadowBox.style.left),
          top: parseInt(shadowBox.style.top)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pic-zoom-container {
  position: relative;
  // width: 100%;
  // height: 100%;
  .physics-size {
    position: absolute;
    display: flex;
    left: 0;
    top: -60px;
    color: $color-danger;
    justify-content: space-between;
    .label {
      color: $color-gray;
      font-weight: 600;
    }
  }
  .oMidBoxImg {
    width: 100%;
    height: 100%;
    // object-fit: contain;
  }
  .shade {
    background-color: rgba(135, 206, 235, 0.5);
    position: absolute;
    top: 100px;
    left: 100px;
    cursor: move;
    width: 200px;
    height: 200px;
  }
  .largeBox {
    width: 400px;
    height: 400px;
    border: 1px solid red;
    position: absolute;
    top: 100%;
    left: 0;
    background: cornsilk;
    overflow: hidden;
    z-index: 10000;
    .largeBoxImg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      object-fit: cover;
    }
  }
}
</style>
