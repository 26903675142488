import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function createKnifeReference(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/sizeCheck/saveOrUpdateSizeCheck',
    method: 'post',
    data
  })
}

export function getKnifeReference(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/sizeCheck/getSizeCheckByReferSizeId',
    method: 'post',
    data
  })
}

//获取psd全局信息
export function getKnifeDocumentInfo(data) {
  return request({
    url: '/externaladmin/productService/referSizePsdConfig/getByReferSizeId',
    method: 'post',
    data
  })
}

export function checkPass(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/sizeCheck/checkPass',
    method: 'post',
    data
  })
}

export function baseSizeInfo(id) {
  return request({
    url: `/api/check_prod/base_info?id=${id}`,
    method: 'get'
  })
}

export function showDesignInfo(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/productPrototype/getByIdForShow',
    method: 'post',
    data
  })
}

export function baseDetail(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/referSize/getReferSizeById',
    method: 'post',
    data
  })
}

export function checkStructPass(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/style/checkPass',
    method: 'post',
    data
  })
}

// 获取产品信息
export function getProInfoList(data = {}) {
  return request({
    url: '/externaladmin/productService/customSpecificProduct/getProInfoList',
    method: 'post',
    data
  })
}
